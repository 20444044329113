/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../../components/Layout'
import ThemeWrapper from '../../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Button, Text, TitleMain, Image, Title } from '@swp/components'
import SiteHeader from '../../components/de/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"Fotogalerie"}>
        <SiteHeader />

        <Column className="--left pb--20 pt--50" name={"uvod"}>
          
          <ColumnWrap className="column__flex --center el--2 pl--20 pr--20 flex--center" columns={"2"}>
            
            <ColumnWrapper >
              
              <Button className="btn-box btn-box--hvr3 btn-box--cbtn2 btn-box--sbtn2 btn-box--left ff--3 fs--18 w--300 swpf--uppercase ls--04 lh--2" href={"/de/"} content={"Zurück"}>
              </Button>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Text className="text-box text-box--right fs--18 w--300 swpf--uppercase ls--04 lh--2 pb--08 pt--08" style={{"maxWidth":""}} content={"<span style=\"color: var(--color-supplementary);\">Café Romanca</span><br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--left pb--60 pt--10" name={"uvod"}>
          
          <ColumnWrap className="column__flex --center el--1 pl--20 pr--20 flex--center" columns={"1"}>
            
            <ColumnWrapper >
              
              <TitleMain className="title-box fs--154 w--300 ls--01 lh--12" style={{"maxWidth":""}} content={"Fotogalerie"}>
              </TitleMain>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--left pb--20 pt--20" name={"uvod"} lightbox={true}>
          
          <ColumnWrap className="column__flex --center el--3 mb--0 mt--0 pb--0 pl--20 pr--20 pt--0 flex--top" columns={"3"}>
            
            <ColumnWrapper className="pb--0 pl--0 pr--0 pt--0" style={{"maxWidth":440}}>
              
              <Image className="mb--40" alt={"Bild anklicken und bearbeiten"} src={"https://cdn.swbpg.com/t/i/template/89/img-1_s=350x_.jpg"} svg={false} ratio={"3:4"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/i/template/89/img-1_s=350x_.jpg 350w, https://cdn.swbpg.com/t/i/template/89/img-1_s=660x_.jpg 660w, https://cdn.swbpg.com/t/i/template/89/img-1_s=860x_.jpg 860w, https://cdn.swbpg.com/t/i/template/89/img-1_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/i/template/89/img-1_s=2000x_.jpg 2000w"} content={null}>
              </Image>

              <Image className="mb--40" alt={"Bild anklicken und bearbeiten"} src={"https://cdn.swbpg.com/t/i/template/89/img-2_s=350x_.jpg"} svg={false} ratio={"3:4"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/i/template/89/img-2_s=350x_.jpg 350w, https://cdn.swbpg.com/t/i/template/89/img-2_s=660x_.jpg 660w, https://cdn.swbpg.com/t/i/template/89/img-2_s=860x_.jpg 860w, https://cdn.swbpg.com/t/i/template/89/img-2_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/i/template/89/img-2_s=2000x_.jpg 2000w"} content={null}>
              </Image>

              <Image className="mb--40" alt={"Bild anklicken und bearbeiten"} src={"https://cdn.swbpg.com/t/i/template/89/img-3_s=350x_.jpg"} svg={false} ratio={"3:4"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/i/template/89/img-3_s=350x_.jpg 350w, https://cdn.swbpg.com/t/i/template/89/img-3_s=660x_.jpg 660w, https://cdn.swbpg.com/t/i/template/89/img-3_s=860x_.jpg 860w, https://cdn.swbpg.com/t/i/template/89/img-3_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/i/template/89/img-3_s=2000x_.jpg 2000w"} content={null}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper className="pb--0 pl--0 pr--0 pt--0" style={{"maxWidth":440}}>
              
              <Image className="mb--40" alt={"Bild anklicken und bearbeiten"} src={"https://cdn.swbpg.com/t/i/template/89/img-2_s=350x_.jpg"} svg={false} ratio={"3:4"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/i/template/89/img-2_s=350x_.jpg 350w, https://cdn.swbpg.com/t/i/template/89/img-2_s=660x_.jpg 660w, https://cdn.swbpg.com/t/i/template/89/img-2_s=860x_.jpg 860w, https://cdn.swbpg.com/t/i/template/89/img-2_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/i/template/89/img-2_s=2000x_.jpg 2000w"} content={null}>
              </Image>

              <Image className="mb--40" alt={"Bild anklicken und bearbeiten"} src={"https://cdn.swbpg.com/t/i/template/89/img-3_s=350x_.jpg"} svg={false} ratio={"3:4"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/i/template/89/img-3_s=350x_.jpg 350w, https://cdn.swbpg.com/t/i/template/89/img-3_s=660x_.jpg 660w, https://cdn.swbpg.com/t/i/template/89/img-3_s=860x_.jpg 860w, https://cdn.swbpg.com/t/i/template/89/img-3_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/i/template/89/img-3_s=2000x_.jpg 2000w"} content={null}>
              </Image>

              <Image className="mb--40" alt={"Bild anklicken und bearbeiten"} src={"https://cdn.swbpg.com/t/i/template/89/img-1_s=350x_.jpg"} svg={false} ratio={"3:4"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/i/template/89/img-1_s=350x_.jpg 350w, https://cdn.swbpg.com/t/i/template/89/img-1_s=660x_.jpg 660w, https://cdn.swbpg.com/t/i/template/89/img-1_s=860x_.jpg 860w, https://cdn.swbpg.com/t/i/template/89/img-1_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/i/template/89/img-1_s=2000x_.jpg 2000w"} content={null}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper className="pb--0 pl--0 pr--0 pt--0" style={{"maxWidth":440}}>
              
              <Image className="mb--40" alt={"Bild anklicken und bearbeiten"} src={"https://cdn.swbpg.com/t/i/template/89/img-1_s=350x_.jpg"} svg={false} ratio={"3:4"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/i/template/89/img-1_s=350x_.jpg 350w, https://cdn.swbpg.com/t/i/template/89/img-1_s=660x_.jpg 660w, https://cdn.swbpg.com/t/i/template/89/img-1_s=860x_.jpg 860w, https://cdn.swbpg.com/t/i/template/89/img-1_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/i/template/89/img-1_s=2000x_.jpg 2000w"} content={null}>
              </Image>

              <Image className="mb--40" alt={"Bild anklicken und bearbeiten"} src={"https://cdn.swbpg.com/t/i/template/89/img-3_s=350x_.jpg"} svg={false} ratio={"3:4"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/i/template/89/img-3_s=350x_.jpg 350w, https://cdn.swbpg.com/t/i/template/89/img-3_s=660x_.jpg 660w, https://cdn.swbpg.com/t/i/template/89/img-3_s=860x_.jpg 860w, https://cdn.swbpg.com/t/i/template/89/img-3_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/i/template/89/img-3_s=2000x_.jpg 2000w"} content={null}>
              </Image>

              <Image className="mb--40" alt={"Bild anklicken und bearbeiten"} src={"https://cdn.swbpg.com/t/i/template/89/img-2_s=350x_.jpg"} svg={false} ratio={"3:4"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/i/template/89/img-2_s=350x_.jpg 350w, https://cdn.swbpg.com/t/i/template/89/img-2_s=660x_.jpg 660w, https://cdn.swbpg.com/t/i/template/89/img-2_s=860x_.jpg 860w, https://cdn.swbpg.com/t/i/template/89/img-2_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/i/template/89/img-2_s=2000x_.jpg 2000w"} content={null}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"partei"} style={{"backgroundColor":"var(--color-supplementary)"}} border={null}>
          
          <ColumnWrap className="column__flex --right el--2 pb--30 pl--20 pr--20 pt--30 flex--top" style={{"maxWidth":""}} columns={"2"}>
            
            <ColumnWrapper style={{"maxWidth":""}}>
              
              <Title className="title-box title-box--left fs--62 swpf--uppercase" content={"<span style=\"color: var(--color-dominant);\">Café Romanca</span>"}>
              </Title>

              <Text className="text-box text-box--left ff--3 fs--16 w--300 ls--04 lh--2 mt--30" style={{"maxWidth":570}} content={"<span style=\"color: var(--color-dominant);\">Fangen Sie an Ihren Text zu schreiben. Dieser Bereich ist für Ihren Text bestimmt. Bei Bedarf können Sie die Farbe des Textblocks, die Schriftgröße und den Zeilenabstand ändern oder den Text ausrichten.</span>"}>
              </Text>

              <Text className="text-box text-box--left mt--0" content={"<a href=\"https://saywebpage.com\">Webproduktion stránek</a>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper style={{"maxWidth":560}}>
              
              <Button className="btn-box btn-box--hvr3 btn-box--cbtn1 btn-box--sbtn2 btn-box--cColor2 btn-box--left ff--1 fs--22 w--300 ls--04 mt--0 pl--0" innerClassName="pb--02 pl--0" content={"<span style=\"color: var(--color-dominant);\">Nach oben</span>"}>
              </Button>

              <Button className="btn-box btn-box--hvr3 btn-box--cbtn1 btn-box--sbtn2 btn-box--cColor2 btn-box--left ff--1 fs--22 w--300 ls--04 mt--20 pl--0" innerClassName="pb--02 pl--0" href={"/de/fotogallerie"} content={"<span style=\"color: var(--color-dominant);\">Fotogalerie</span>"}>
              </Button>

              <Button className="btn-box btn-box--hvr3 btn-box--cbtn1 btn-box--sbtn2 btn-box--cColor2 btn-box--left ff--1 fs--22 w--300 ls--04 mt--20 pl--0" innerClassName="pb--02 pl--0" href={"/de/menu"} content={"<span style=\"color: var(--color-dominant);\">Menü</span>"}>
              </Button>

              <Button className="btn-box btn-box--hvr3 btn-box--cbtn1 btn-box--sbtn2 btn-box--cColor2 btn-box--left ff--1 fs--22 w--300 ls--04 mt--20 pl--0" innerClassName="pb--02 pl--0" href={"/de/buchung"} content={"<span style=\"color: var(--color-dominant);\">Buchung"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}